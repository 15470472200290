import { hashCode } from "utils/hash";

import {
  ClassItem,
  ClassResponse as ClassSearchResponse,
  ClassResponse2,
} from "./types";

export enum EField {
  orgCode = "orgCode",
  courseId = "courseId",
  semesterCode = "semesterCode",
  semesterName = "semesterName",
  semesterStartDate = "semesterStartDate",
  semesterEndDate = "semesterEndDate",
  sessionCode = "sessionCode",
  sessionName = "sessionName",
  sessionStartDate = "sessionStartDate",
  sessionEndDate = "sessionEndDate",
  classSection = "classSection",
  subjectCode = "subjectCode",
  subjectName = "subjectName",
  catalogNumber = "catalogNumber",
  course = "course",
  academicCareerCode = "academicCareerCode",
  academicCareerName = "academicCareerName",
  classNumber = "classNumber",
  component = "component",
  statusCode = "statusCode",
  statusName = "statusName",
  schedulePrint = "schedulePrint",
  enrollmentCapacity = "enrollmentCapacity",
  waitCapacity = "waitCapacity",
  minimumEnrollment = "minimumEnrollment",
  enrollmentTotal = "enrollmentTotal",
  enrollmentStatus = "enrollmentStatus",
  waitTotal = "waitTotal",
  campusCode = "campusCode",
  campusName = "campusName",
  locationCode = "locationCode",
  locationName = "locationName",
  classFormatCode = "classFormatCode",
  classFormatName = "classFormatName",
  classStartDate = "classStartDate",
  classEndDate = "classEndDate",
  cancelDate = "cancelDate",
  classStartTime = "classStartTime",
  classEndTime = "classEndTime",
  classMonday = "classMonday",
  classTuesday = "classTuesday",
  classWednesday = "classWednesday",
  classThursday = "classThursday",
  classFriday = "classFriday",
  classSaturday = "classSaturday",
  classSunday = "classSunday",
  faculty = "faculty",
  facilityId = "facilityId",
  buildingCode = "buildingCode",
  buildingName = "buildingName",
  room = "room",
  classNotesSequence = "classNotesSequence",
  creditHours = "creditHours",
  gradingBasis = "gradingBasis",
  courseTitle = "courseTitle",
  courseDescription = "courseDescription",
  classNotes = "classNotes",
  firstEnrollmentDate = "firstEnrollmentDate",
  lastEnrollmentDate = "lastEnrollmentDate",

  // list fields
  classFormatCodeList = "classFormatCodeList",
  semesterStartDateList = "semesterStartDateList",
  sessionStartDateList = "sessionStartDateList",
  locationCodeList = "locationCodeList",
}

const FieldDataType: Record<EField, "string" | "boolean" | "number" | "list"> =
  {
    orgCode: "string",
    courseId: "string",
    semesterCode: "string",
    semesterName: "string",
    semesterStartDate: "string",
    semesterEndDate: "string",
    sessionCode: "string",
    sessionName: "string",
    sessionStartDate: "string",
    sessionEndDate: "string",
    classSection: "string",
    subjectCode: "string",
    subjectName: "string",
    catalogNumber: "string",
    course: "string",
    academicCareerCode: "string",
    academicCareerName: "string",
    classNumber: "string",
    component: "string",
    statusCode: "string",
    statusName: "string",
    schedulePrint: "string",
    enrollmentCapacity: "number",
    waitCapacity: "number",
    minimumEnrollment: "number",
    enrollmentTotal: "number",
    enrollmentStatus: "string",
    waitTotal: "number",
    campusCode: "string",
    campusName: "string",
    locationCode: "string",
    locationName: "string",
    classFormatCode: "string",
    classFormatName: "string",
    classStartDate: "string",
    classEndDate: "string",
    cancelDate: "string",
    classStartTime: "string",
    classEndTime: "string",
    classMonday: "boolean",
    classTuesday: "boolean",
    classWednesday: "boolean",
    classThursday: "boolean",
    classFriday: "boolean",
    classSaturday: "boolean",
    classSunday: "boolean",
    faculty: "string",
    facilityId: "string",
    buildingCode: "string",
    buildingName: "string",
    room: "string",
    classNotesSequence: "string",
    creditHours: "number",
    gradingBasis: "string",
    courseTitle: "string",
    courseDescription: "string",
    classNotes: "string",
    firstEnrollmentDate: "string",
    lastEnrollmentDate: "string",

    // list fields
    classFormatCodeList: "list",
    semesterStartDateList: "list",
    sessionStartDateList: "list",
    locationCodeList: "list",
  };

type ExactlyOneKey<K extends keyof any, V, KK extends keyof any = K> = {
  [P in K]: { [Q in P]: V } & { [Q in Exclude<KK, P>]?: never } extends infer O
    ? { [Q in keyof O]: O[Q] }
    : never;
}[K];

interface IOptions {
  equals: string | number | boolean;
  notEquals: string | number | boolean;
  gt: string | number;
  lt: string | number;
  gte: string | number;
  lte: string | number;
  contains: string | number;
  in: string;
}

export type IField = ExactlyOneKey<EField, Partial<IOptions>>;

export interface IAND {
  AND: Array<IField | IAND | IOR>;
}

export interface IOR {
  OR: Array<IField | IAND | IOR>;
}

export type IFilter = IAND | IOR;
type ISelect = EField[];

type IOrderbyOptions = "asc" | "desc";

type IOrderby = Partial<Record<EField, IOrderbyOptions>>;

export interface IQuery {
  select: ISelect;
  filter: IFilter;
  orderby?: IOrderby;
  search?: string; // free text search
  top?: number;
  skip?: number;
}

const fieldParser = (field: IField) => {
  const [fieldName, opeObj] = Object.entries(field)[0];
  const [opeType, opeVal] = Object.entries(opeObj)[0];

  const dataType = FieldDataType[fieldName as EField];

  let opeValParsed;

  if (dataType === "string") {
    opeValParsed = `'${opeVal}'`;
  } else if (dataType === "list") {
    opeValParsed = (opeVal as string[]).map((item) => `'${item}'`).join(",");
  } else {
    opeValParsed = opeVal;
  }

  let strOutput = "";
  if (opeType === "equals") {
    strOutput += `${fieldName} eq ${opeValParsed}`;
  } else if (opeType === "notEquals") {
    strOutput += `${fieldName} ne ${opeValParsed}`;
  } else if (opeType === "gt") {
    strOutput += `${fieldName} gt ${opeValParsed}`;
  } else if (opeType === "lt") {
    strOutput += `${fieldName} lt ${opeValParsed}`;
  } else if (opeType === "gte") {
    strOutput += `${fieldName} ge ${opeValParsed}`;
  } else if (opeType === "lte") {
    strOutput += `${fieldName} le ${opeValParsed}`;
  } else if (opeType === "contains") {
    strOutput += `contains(${fieldName}, ${opeValParsed})`;
  } else if (opeType === "in") {
    strOutput += `${fieldName.replace("List", "")} in (${opeValParsed})`;
  }

  return strOutput;
};

const parseFilter = (object: IAND | IOR, filter = "") => {
  const [boolOpe, valueList] = Object.entries(object)[0];
  let boolOpeTxt = "";
  if (boolOpe === "AND") {
    boolOpeTxt = "and";
  } else if (boolOpe === "OR") {
    boolOpeTxt = "or";
  }

  if (valueList.length === 0) return filter;

  if (valueList.length > 1) filter += "(";
  for (let i = 0; i < valueList.length; i++) {
    const value = valueList[i];
    const _key = Object.keys(value)[0];
    if (_key === "AND") {
      filter += parseFilter(value as IAND);
    } else if (_key === "OR") {
      filter += parseFilter(value as IOR);
    } else {
      filter += fieldParser(value as IField);
    }

    if (i !== valueList.length - 1) {
      filter += ` ${boolOpeTxt} `;
    }
  }
  if (valueList.length > 1) filter += ")";
  return filter;
};

const parseSelect = (fields: ISelect) => {
  return fields.join(",");
};

const parseOrderBy = (field: IOrderby) => {
  return Object.entries(field)
    .map(([key, value]) => `${key} ${value}`)
    .join(",");
};

const cacheResults: Record<string, any> = {};

export const fetchValues = async (
  query: IQuery,
  type: "value" | "class" = "value",
  cache = false
) => {
  /**
   * type value gives distinct values (slow)
   * type class gives all values (fast)
   */

  const env = window.location.hostname;
  let url = "";
  if (env === "portaldev.umgc.edu") {
    url = `https://svcgateway-nonprod.umgc.edu/dev-classroom-ea/v1/${type}`;
  } else if (env === "portalqat.umgc.edu") {
    url = `https://svcgateway-nonprod.umgc.edu/qat-classroom-ea/v1/${type}`;
  } else if (env === "portalstg.umgc.edu") {
    url = `https://svcgateway-nonprod.umgc.edu/stg-classroom-ea/v1/${type}`;
  } else if (env === "students.umgc.edu") {
    url = `https://svcgateway.umgc.edu/classroom-ea/v1/${type}`;
  }

  const urlObj = new URL(url);
  urlObj.searchParams.append("select", parseSelect(query.select));
  urlObj.searchParams.append("filter", parseFilter(query.filter));

  if (query.search) {
    urlObj.searchParams.append("search", query.search);
  }

  if (query.orderby) {
    urlObj.searchParams.append("orderby", parseOrderBy(query.orderby));
  }

  if (query.top !== undefined && query.skip !== undefined) {
    urlObj.searchParams.append("top", String(query.top));
    urlObj.searchParams.append("skip", String(query.skip));
  }

  if (cache) {
    const code = hashCode(urlObj.href);
    const results = cacheResults[code];
    if (results) {
      console.log("Retrieved from cache");
      console.log(urlObj.href);
      console.log(results);
      return results;
    }
  }

  const result = await fetch(urlObj.href);

  // const json = await result.json();
  const json = await flattenClassResponse(result);

  // for semesterName field swap year and term
  json.value = json.value.map((item: any) => {
    if (item.semesterName) {
      const [year, term] = item.semesterName.split(" ");
      item.semesterName = `${term} ${year}`;
    }
    return item;
  });

  // replace "Online via the Web" to "Online"
  json.value = json.value.map((item: any) => {
    if (item?.locationName === "Online via the Web") {
      item.locationName = "Online";
    }
    if (item?.classFormatName === "Online via the Web") {
      item.classFormatName = "Online";
    }
    return item;
  });

  if (cache) cacheResults[hashCode(urlObj.href)] = json;

  return json as any;
};

const flattenClassResponse = async (
  response: Response
): Promise<ClassSearchResponse> => {
  const classResponse: ClassResponse2 = await response.json();

  const flatClassResponse: Partial<ClassSearchResponse> = {
    "@odata.count": classResponse.totalCount,
    "@odata.nextLink": "",
    "@odata.context": "",
    value: [],
  };

  flatClassResponse.value = classResponse.searchResults.map((item) => {
    return {
      orgCode: item?.orgCode,
      courseId: item?.course?.courseId,
      semesterCode: item?.semester?.semesterCode,
      semesterName: item?.semester?.semesterName,
      semesterStartDate: item?.semester?.semesterStartDate,
      semesterEndDate: item?.semester?.semesterEndDate,
      sessionCode: item?.semester?.sessions?.[0]?.sessionCode,
      sessionName: item?.semester?.sessions?.[0]?.sessionName,
      sessionStartDate: item?.semester?.sessions?.[0]?.sessionStartDate,
      sessionEndDate: item?.semester?.sessions?.[0]?.sessionEndDate,
      classSection: item?.class?.classSection,
      subjectCode: item?.course?.subjectCode,
      subjectName: item?.course?.subjectName,
      catalogNumber: item?.course?.catalogNumber,
      course: item?.course?.course,
      academicCareerCode: item?.course?.academicCareerCode,
      academicCareerName: item?.course?.academicCareerName,
      classNumber: item?.class?.classNumber,
      component: item?.course?.component,
      statusCode: item?.class?.statusCode,
      statusName: item?.class?.statusName,
      schedulePrint: item?.class?.schedulePrint,
      enrollmentCapacity: item?.class?.enrollmentCapacity,
      waitCapacity: item?.class?.waitCapacity,
      minimumEnrollment: item?.class?.minimumEnrollment,
      enrollmentTotal: item?.class?.enrollmentTotal,
      waitTotal: item?.class?.waitTotal,
      campusCode: item?.class?.campusCode,
      campusName: item?.class?.campusName,
      locationCode: item?.class?.locationCode,
      locationName: item?.class?.locationName,
      classFormatCode: item?.class?.classFormatCode,
      classFormatName: item?.class?.classFormatName,
      classStartDate: item?.class?.classStartDate,
      classEndDate: item?.class?.classEndDate,
      cancelDate: item?.class?.cancelDate,
      classStartTime: item?.class?.classStartTime,
      classEndTime: item?.class?.classEndTime,
      classMonday: item?.class?.classDays?.monday,
      classTuesday: item?.class?.classDays?.tuesday,
      classWednesday: item?.class?.classDays?.wednesday,
      classThursday: item?.class?.classDays?.thursday,
      classFriday: item?.class?.classDays?.friday,
      classSaturday: item?.class?.classDays?.saturday,
      classSunday: item?.class?.classDays?.sunday,
      faculty: item?.class?.faculty?.[0]?.name,
      facilityId: item?.class?.facilityId,
      buildingCode: item?.class?.buildingCode,
      buildingName: item?.class?.buildingName,
      room: item?.class?.room,
      // classNotesSequence: item?.class?.classNotesSequence,
      creditHours: item?.class?.studentCreditHours,
      gradingBasis: item?.class?.gradingBasis,
      courseTitle: item?.course?.courseTitle,
      courseDescription: item?.course?.courseDescription,
      courseSyllabus: item?.course?.courseSyllabus,
      classNotes: item?.class?.classNotes,
    } as unknown as ClassItem;
  });

  // console.log({ flatClassResponse });

  return flatClassResponse as ClassSearchResponse;
};
